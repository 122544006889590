<template>
  <b-modal
    :active="isModalOpen"
    has-modal-card
    trap-focus
    :destroy-on-hide="false"
    :on-cancel="onCloseModalCallback"
    aria-role="dialog"
    aria-modal
    width="600px"
  >
    <template>
      <div
        class="modal-card"
        style="width: 630px;height:194px;border-radius: 10px"
      >
        <section class="modal-card-body">
          <header>
            <div class="is-flex" style="justify-content: space-between">
              <div style="font-size: 20px;font-weight: 900;color: black;">
                {{ titleModal }}
              </div>
              <div @click="onCloseModalCallback" class="close-modal-btn">
                Close
              </div>
            </div>
          </header>
          <slot name="content"></slot>
        </section>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    isModalOpen: Boolean,
    onCloseModalCallback: Function,
    titleModal: String,
  },
  data() {
    return {
      isComponentModalActive: false,
    }
  },
}
</script>

<style>
.close-modal-btn:hover {
  cursor: pointer;
}
</style>
